import Axios from 'axios';
import { data } from 'flickity';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      company: {
      },
      community: {
        users: [],
      },
      formSearch: {
        name: '',
        state: '',
        specialities: [],
      },
      searchUsers: [],
      formInvite: {
        name: '',
        email: '',
      },
      states: ['São Paulo'],
      specialities: [],
      specialitiesInput: '',
      collapse: {
        outside: true,
      },
      pageToLoad: 2,
      verticalScroll: 0,
      cantLoadMoreUsers: false
    }
  },

  mounted() {
    var _this = this;


    axios
      .get('/api/v1/companies/' + this.me.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.company = response.data.data;
          _this.community = _this.company.community;
        } else {
          console.log(response.data.error);
        }
      });

    axios
      .get('/api/v1/states', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.states = response.data.data;
        } else {
          console.log(response.data.error);
        }
      });

    axios
      .get('/api/v1/specialities', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.specialities = response.data.data;
        }
      });
  },

  computed: {
    queryUrl() {
      let url = '?status=2&professionals=1&notincommunity=' + this.community.id; //
      if (this.formSearch.name && this.formSearch.name.length > 3) url += '&name=' + this.formSearch.name;
      if (this.formSearch.state) url += '&state=' + this.formSearch.state;
      if (this.formSearch.specialities.length) {
        let temp = this.formSearch.specialities.map((e) => e.id);
        url += '&specialities=' + encodeURI(temp.join(';'));
      };
      return url;
    },
  },

  methods: {
    inviteOutside() {
      var data = {
        name: this.formInvite.name,
        mail: this.formInvite.email,
      };
      if (!data.name) {
        alert('Complete o nome');
        return;
      };
      if (!data.mail) {
        alert('Complete o email');
        return;
      };
      axios
        .post('/api/v1/communities/' + this.community.id + '/invite-outside', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            // _this.community = response.data.data;
            // console.log(_this.community);
            this.formInvite.name = '';
            this.formInvite.email = '';
            alert('Convite enviado');
          } else {
            console.log(response.data.error);
          }
        });
    },

    addMember(user) {
      axios
        .post('/api/v1/communities/' + this.community.id + '/members', { userId: user.id }, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            // _this.errors = response.data.error;
            console.log(response.data.error);
          } else {
            // this.$router.go(); // remover da lista de users
            let index = this.searchUsers.map(e => e.id).indexOf(user.id);
            if (index > -1) this.searchUsers.splice(index, 1);
          }
        });
    },

    validateListComplementares(e) {
      if (this.formSearch.specialities.length <= 4) {
        let text = this.specialitiesInput;
        let index = this.specialities.map(function (e) { return e.pt; }).indexOf(text);
        let double = this.formSearch.specialities.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.formSearch.specialities.push(this.specialities[index]);
        }
      }
      this.specialitiesInput = '';
    },

    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.formSearch.specialities.indexOf(s);
      if (index > -1) {
        this.formSearch.specialities.splice(index, 1);
      }
    },

    loadMoreUsers() {
      this.verticalScroll = window.scrollY

      try {
        axios
          .get('/api/v1/users' + this.queryUrl + '&paginate=12' + '&page=' + this.pageToLoad, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            if (!response.data.error) {
              this.searchUsers = [...this.searchUsers, ...response.data.data];
              if (response.data.last_page <= this.pageToLoad) {
                this.cantLoadMoreUsers = true
              }
            } else {
              console.log(response.data.error);
            }
          });

        this.pageToLoad++
      } catch (err) {
        console.log(err)
      }
    }
  },

  watch: {
    queryUrl() {
      axios
        .get('/api/v1/users' + this.queryUrl + '&paginate=12', {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.searchUsers = response.data.data;
          } else {
            console.log(response.data.error);
          }
        });
    },

    searchUsers() {
      setTimeout(() => window.scrollTo(0, this.verticalScroll), 100)
    },
  },
}
